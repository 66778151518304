<template>
  <BaseModular
    title="规范服务 / 阳光清廉 / 示范引领 / 降本增效"
    subTitle="公司简介"
  >
    <div class="Companyintro_wrap">
      <div v-html="CompanyProfile"></div>

      <div class="Companyintro_content" v-if="false">
        <div
          @click="setHtml(item)"
          class="Companyintro_item"
          style="cursor: pointer"
          v-for="item in contactData.ArticleData"
          :key="item"
        >
          <div class="Companyintro_item_img_wrap" style="cursor: pointer">
            <img
              style="height: 436px !important; width: 582px !important"
              :src="
                item.thumbnailUrlList.length > 0
                  ? getUrl(item.thumbnailUrlList[0].name)
                  : imgData.ContactImg.content.item1 /*默认*/
              "
            />
            <div class="Companyintro_item_img_info" style="cursor: pointer">
              <p>{{ item.title }}</p>
              <p>{{ item.createTime }}</p>
            </div>
          </div>

          <div class="Companyintro_item_content" style="cursor: pointer">
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>
  </BaseModular>
</template>

<script>
import imgData from "@/data";
import { reactive, computed } from "vue";
import { mainStore, stateStore } from "@/store";
import { storeToRefs } from "pinia";
import BaseModular from "@/components/BaseModular/BaseModular";
import { useRouter } from "vue-router";
import { fa } from "element-plus/es/locale";
export default {
  components: { BaseModular },
  setup() {
    const storeOfmain = storeToRefs(mainStore());
    const storeOfstate = stateStore();
    const router = useRouter();
    const getCategoryAllData = (categoryName, firstLength, lastLength) => {
      return {
        CategoryData: computed(() => {
          return storeToRefs(storeOfmain).AllNavData.value.find(
            (v) => v.categoryName == categoryName
          );
        }),
        ArticleData: computed(() => {
          return storeToRefs(storeOfmain).Article.value[categoryName]
            ? storeToRefs(storeOfmain).Article.value[categoryName].slice(
                firstLength,
                lastLength
              )
            : storeToRefs(storeOfmain).Article.value[categoryName];
        }),
      };
    };
    const CompanyProfile = computed(
      () => storeToRefs(storeOfmain).CompanyProfile
    );
    const contactData = getCategoryAllData("联系我们", 0, 2);
    const itemData = Object.values(imgData.ContactImg.content).map(
      (v, index) => ({
        title:
          index == 0 ? "第一次股东大会" : "海衡招标阳光集采平台建设项目启动会",
        time: index == 0 ? "2022/7/12" : "2022/7/25",
        url: v,
        content:
          index == 0
            ? "7月12日，在区公共交易资源中心432会议室召开第一次股东大会，会议一致通过公司《章程》；会议选举了董事；会议一致通过《集采平台建设方案议案》和《公司交易办公场所建设方案议案》。"
            : "7月25日，在区公共资源交易中心432会议室，召开集采平台建设项目启动会。",
      })
    );
    const getUrl = (name) => {
      return window.location.origin + "/" + name;
    };
    const setHtml = (item) => {
      storeOfstate.setactiveHtml(
        item.content,
        item.title,
        item.createTime,
        item
      );
      window.open(
        router.resolve({
          path: "/InfoView",
          query: { id: item.id, type: "联系我们" },
        }).href,
        "_blank"
      );
    };

    const data = reactive({
      itemData,
      contactData,
      CompanyProfile,
    });

    return {
      ...data,
      imgData,
      setHtml,
      getUrl,
    };
  },
};
</script>

<style scoped>
@import url("./Companyintro.css");
</style>

